import React, { useState } from "react";
import axios from "axios";
import { config } from "../../config";

const apiUrl = config.apiUrl;
const CreateInstructor = ({ onInstructorCreated }) => {
  const [formData, setFormData] = useState({
    image: null,
    name: "",
    designation: "",
    desc: "",
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("image", formData.image);
    data.append("name", formData.name);
    data.append("designation", formData.designation);
    data.append("desc", formData.desc);

    try {
      await axios.post(`${apiUrl}/instructors`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Instructor Created Successfully!");
      onInstructorCreated(); // Notify parent component
    } catch (err) {
      console.error("Error creating instructor:", err);
      setError("An error occurred while creating the instructor.");
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Create Instructor</h2>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Image</label>
          <input
            type="file"
            name="image"
            onChange={handleChange}
            className="border w-full p-2"
            accept="image/*"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Designation</label>
          <input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <textarea
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            className="border w-full p-2"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Create Instructor
        </button>
      </form>
    </div>
  );
};

export default CreateInstructor;
