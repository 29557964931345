import React from "react";
import { FaBars } from "react-icons/fa";

const Navbar = ({ toggleSidebar, navigate }) => {
  return (
    <nav className="bg-white shadow-lg w-full fixed top-0 z-50 flex justify-between items-center p-4">
      {/* Logo Section */}
      <div className="flex items-center">
        <img
          src="https://via.placeholder.com/50"
          alt="Logo"
          className="h-10 w-10 mr-3"
        />
        <span className="text-xl font-bold text-blue-800">My Dashboard</span>
      </div>

      {/* Navbar Menu for Desktop */}
      <div className="hidden md:flex space-x-6 items-center">
        <button
          onClick={() => navigate("/courses")}
          className="flex items-center space-x-2 text-blue-800 hover:bg-gray-200 p-2 rounded transition-all duration-300"
        >
          <span>Courses</span>
        </button>
        <button
          onClick={() => navigate("/analytics")}
          className="flex items-center space-x-2 text-blue-800 hover:bg-gray-200 p-2 rounded transition-all duration-300"
        >
          <span>Analytics</span>
        </button>
        <button
          onClick={() => navigate("/settings")}
          className="flex items-center space-x-2 text-blue-800 hover:bg-gray-200 p-2 rounded transition-all duration-300"
        >
          <span>Settings</span>
        </button>
        <button
          onClick={() => navigate("/logout")}
          className="flex items-center space-x-2 text-red-600 hover:bg-red-200 p-2 rounded transition-all duration-300"
        >
          <span>Logout</span>
        </button>
      </div>

      {/* Mobile Menu Toggle */}
      <button onClick={toggleSidebar} className="md:hidden text-blue-800">
        <FaBars size={24} />
      </button>
    </nav>
  );
};

export default Navbar;
