import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Affiliates/Navbar";
import Sidebar from "../../components/Affiliates/Sidebar";
import AffiliateLink from "./AffiliateLink";
import Income from "./Income";



const Dashboard = () => {
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsOverlayVisible(!isSidebarOpen);
  };

  const handleOverlayClick = () => {
    setIsSidebarOpen(false);
    setIsOverlayVisible(false);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "affiliate":
        return (
          <div className="flex flex-col justify-center items-center">
            <div className="">
              <AffiliateLink />
            </div>
          </div>
        );

        case "income":
        return (
          <div className="flex flex-col justify-center items-center">
            <div className="">
              <Income />
            </div>
          </div>
        );
      
      default:
        return (
          <div className="text-gray-700">
            Welcome to the Dashboard
          </div>
        );
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 mx-auto">
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} navigate={navigate} />

      {/* Sidebar */}
      {isOverlayVisible && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={handleOverlayClick}
        ></div>
      )}
      <div className="flex flex-col mt-20">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          toggleSidebar={toggleSidebar}
          navigate={navigate}
          isOverlayVisible={isOverlayVisible}
          setIsSidebarOpen={setIsSidebarOpen}
          setIsOverlayVisible={setIsOverlayVisible}
        />
      </div>

      {/* Content Area */}
      <div className="flex-grow p-6 ml-[65px] mr-2 mt-4 transition-all duration-300 pt-24 md:pt-20">
        <div className="bg-white shadow-lg rounded-lg p-6">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Dashboard;
