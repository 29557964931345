import React, { useEffect, useState } from 'react';
import Navbar from '../components/NavBar'; // Navbar কম্পোনেন্ট
import Footer from '../components/Footer'; // Footer কম্পোনেন্ট
import axios from 'axios';
import { config } from '../config'; // Config ফাইল থেকে API এবং ইমেজ URL

// Config থেকে API URL এবং Image URL
const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const Instructors = () => {
  const [instructors, setInstructors] = useState([]); // Instructors এর ডেটা
  const [error, setError] = useState(""); // Error স্টেট
  const [loading, setLoading] = useState(true); // লোডিং স্টেট

  // Data Fetch করার জন্য useEffect
  useEffect(() => {
    axios
      .get(`${apiUrl}/instructors`) // API কল
      .then((response) => {
        console.log(response.data); // ডেটা চেক করার জন্য লগ
        setInstructors(response.data.instructors || []); // ডেটা সেট করা
        setLoading(false); // লোডিং বন্ধ করা
      })
      .catch((error) => {
        console.error(error); // Error লগ করা
        setError('ডাটা লোড করতে সমস্যা হয়েছে'); // Error মেসেজ সেট করা
        setLoading(false); // লোডিং বন্ধ করা
      });
  }, []);

  // যদি লোডিং হয়
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <h1 className="text-2xl font-semibold text-gray-600">Loading...</h1>
      </div>
    );
  }

  // যদি Error থাকে
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <h1 className="text-2xl font-semibold text-red-600">{error}</h1>
      </div>
    );
  }

  return (
    <>
      <Navbar /> {/* Navbar */}
      <section className="min-h-screen py-16 bg-gray-100">
        <div className="container mx-auto px-6 lg:px-12">
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-12">
            আমাদের প্রশিক্ষকদের সঙ্গে পরিচিত হন
          </h1>

          {/* Instructors Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {instructors.map((instructor, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105"
              >
                {/* Instructor Image */}
                <img
                  src={`${imageUrl}/${instructor.image}`} // Image URL
                  alt={instructor.name}
                  className="rounded-full w-40 h-40 object-cover mb-6 border-4 border-gray-200"
                />
                {/* Instructor Name */}
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  {instructor.name}
                </h2>
                {/* Instructor Designation */}
                <h3 className="text-xl text-gray-500 mb-4">
                  {instructor.designation}
                </h3>
                {/* Instructor Description */}
                <p className="text-gray-600">{instructor.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer /> {/* Footer */}
    </>
  );
};

export default Instructors;
