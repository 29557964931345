import React from 'react';
import { Link } from "react-router-dom";

const MobileNavbar = ({ activeMenu, handleSetActiveMenu, toggleMenu }) => {
  const links = [
    { name: "Home", path: "/" },
    { name: "All Courses", path: "/courses" },
    { name: "About Us", path: "/about" },
    { name: "Instructors", path: "/instructors" },
    { name: "Contact", path: "/contact" },
  ];

  return (
    <div>
      {/* Slide-in Navbar */}
      <div
        className={`fixed top-0 right-0 h-full w-[300px] bg-gray-800 transform transition-transform duration-300 ease-in-out lg:hidden z-40`}
      >
        <div className="flex flex-col items-center space-y-4 py-16">
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className={`text-gray-100 hover:text-green-500 hover:border-b-[4px] hover:border-green-500 pb-2 no-underline ${
                activeMenu === link.path ? "text-green-500 border-b-[4px] border-green-500 font-bold underline" : ""
              }`}
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>

      {/* Background overlay */}
      <div
        className="fixed inset-0 bg-black opacity-50 z-30"
        onClick={toggleMenu} // Close menu when clicking the overlay
      ></div>
    </div>
  );
};

export default MobileNavbar;
