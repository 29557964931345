import React, { useEffect, useState } from "react";
import axios from "axios";
import UpdateInstructor from "./UpdateInstructor";
import { config } from "../../config";

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const ViewInstructors = () => {
  const [instructors, setInstructors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingInstructor, setEditingInstructor] = useState(null);
  const [photo, setPhoto] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    desc: "",
  });

  // Fetch instructors from API
  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await axios.get(`${apiUrl}/instructors`);
        setInstructors(response.data.instructors);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching instructors:", err);
        setLoading(false);
      }
    };

    fetchInstructors();
  }, []);

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this instructor?")) {
      try {
        await axios.delete(`${apiUrl}/instructordelete/${id}`);
        setInstructors((prev) => prev.filter((instructor) => instructor.id !== id));
      } catch (err) {
        console.error("Error deleting instructor:", err);
      }
    }
  };

  // Handle Edit Button Click
  const handleEditClick = (instructor) => {
    setEditingInstructor(instructor.id);
    setFormData({
      name: instructor.name,
      designation: instructor.designation,
      desc: instructor.desc,
      image: instructor.image,
    });
  };

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setPhoto(files[0]);
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Handle Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editingInstructor) {
      setError("No instructor selected for editing.");
      return;
    }

    const data = new FormData();
    data.append("_method", "PUT");
    if (photo) data.append("image", photo);
    data.append("name", formData.name);
    data.append("designation", formData.designation);
    data.append("desc", formData.desc);

    try {
      await axios.post(
        `${apiUrl}/instructorsupdate/${editingInstructor}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert("Instructor updated successfully");

      // Update the local instructor list
      setInstructors((prev) =>
        prev.map((instructor) =>
          instructor.id === editingInstructor
            ? { ...instructor, ...formData, image: photo }
            : instructor
        )
      );

      setEditingInstructor(null); // Close the edit form
    } catch (err) {
      console.error("Error updating instructor:", err);
      setError("An error occurred while updating the instructor.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Instructor List</h2>
      {error && <p className="text-red-500">{error}</p>}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Image</th>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Designation</th>
            <th className="border border-gray-300 px-4 py-2">Description</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {instructors.map((instructor) => (
            <tr key={instructor.id}>
              <td className="border border-gray-300 px-4 py-2">
                {instructor.image && (
                  <img
                    src={`${imageUrl}/${instructor.image}`}
                    alt={instructor.name || "Instructor"}
                    className="h-12 w-12 object-cover"
                  />
                )}
              </td>
              <td className="border border-gray-300 px-4 py-2">{instructor.name}</td>
              <td className="border border-gray-300 px-4 py-2">{instructor.designation}</td>
              <td className="border border-gray-300 px-4 py-2">{instructor.desc}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleEditClick(instructor)}
                  className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(instructor.id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingInstructor && (
        <UpdateInstructor
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setPhoto={setPhoto}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};

export default ViewInstructors;
