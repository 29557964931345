import React from "react";
import { Navigate } from "react-router-dom";

// PrivateRoute component
const PrivateRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  if (!token) {
    // যদি ইউজার লগ ইন না করে, তাহলে তাকে লগিন পেজে রিডাইরেক্ট করবো
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    // যদি ইউজারের রোল অনুমোদিত রোলের সাথে মিলে না যায়, তাহলে তাকে একটি Access Denied পেজ বা অন্য পেজে পাঠানো হবে
    return <Navigate to="/login" />;
  }

  // যদি সব ঠিক থাকে, তাহলে children রেন্ডার হবে (যে পেজটি প্রাইভেট রাউটের মাধ্যমে ঢুকতে চাচ্ছে)
  return children;
};

export default PrivateRoute;
