import React, { useEffect, useState } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { MdAttachEmail, MdAddLocationAlt } from "react-icons/md";
import { motion } from 'framer-motion';
import Footer from '../components/Footer';
import Navbar from '../components/NavBar';
import axios from 'axios';
import { config } from '../config';

const apiUrl = config.apiUrl;

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    course: "",
    questions: "",
  });

  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(""); // For success message

  useEffect(() => {
    axios
      .get(`${apiUrl}/courses`)
      .then((response) => {
        setCourses(response.data.courses || []);
        setLoading(false);
      })
      .catch(() => {
        setError("Data Not Fetch!");
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage(""); // Reset the success message before submitting

    axios
      .post(`${apiUrl}/contacts`, formData)
      .then((response) => {
        console.log('Data submitted successfully:', response.data);
        setSuccessMessage("Your message has been sent successfully!"); // Show success message
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          course: "",
          questions: "",
        }); // Reset form
      })
      .catch((error) => {
        console.log('Server Error:', error.response?.data);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const contacts = {
    phone: "(+880) 1756767688",
    email: "support@impulseitsolution.com",
    address: "Back Side of Animal Hospital,Puran Bazar Road, Madaripur Sadar, Madaripur",
  };

  return (
    <>
      <Navbar />
      <div className="min-h-[80vh] flex flex-col justify-center bg-gray-50 py-16 lg:px-0">
        <div className="container mx-auto px-6 lg:px-0">
          <div className="flex flex-col lg:flex-row lg:justify-center items-center gap-12">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="lg:basis-2/3 w-full bg-white shadow-lg rounded-xl p-10 order-2 lg:order-1"
            >
              <h2 className="font-extrabold text-green-600 text-4xl mb-6">আসুন একসঙ্গে কাজ করি</h2>
              <p className="text-gray-500 mb-6">
              আমরা আপনার সাথে যোগাযোগ করতে আগ্রহী! নিচের ফর্মটি পূরণ করুন, আমরা শীঘ্রই আপনার কাছে ফিরতি বার্তা পাঠাবো।
              </p>

              {/* Success Message */}
              {successMessage && (
                <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-md shadow-md">
                  {successMessage}
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="John"
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500 transition"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Doe"
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500 transition"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="you@example.com"
                    className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500 transition"
                  />
                </div>

                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="01XXXXXXXXXXX"
                    className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500 transition"
                  />
                </div>

                <div>
                  <label
                    htmlFor="course"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Select Your Interest
                  </label>
                  <select
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                    className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500 transition"
                  >
                    <option value="">Select Your Interest</option>
                    {courses.map((course, index) => (
                      <option key={index} value={course.name}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="questions"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Additional Information
                  </label>
                  <textarea
                    name="questions"
                    value={formData.questions}
                    onChange={handleChange}
                    placeholder="Please Provide Me Your Requirement!"
                    className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500 transition"
                    rows="4"
                  ></textarea>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </motion.div>

            {/* Contact Info Section */}
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="lg:basis-1/3 w-full flex flex-col gap-8 items-start p-8"
            >
              <div className="flex items-center gap-4">
                <div className="bg-green-100 shadow-md p-4 rounded-full text-green-500">
                  <FaPhoneAlt size={24} />
                </div>
                <div className="leading-5">
                  <p className="text-lg font-semibold text-gray-800">Phone</p>
                  <p className="text-sm text-gray-600">{contacts.phone}</p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="bg-green-100 shadow-md p-4 rounded-full text-green-500">
                  <MdAttachEmail size={24} />
                </div>
                <div className="leading-5">
                  <p className="text-lg font-semibold text-gray-800">Email</p>
                  <p className="text-sm text-gray-600">{contacts.email}</p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="bg-green-100 shadow-md p-4 rounded-full text-green-500">
                  <MdAddLocationAlt size={24} />
                </div>
                <div className="leading-5">
                  <p className="text-lg font-semibold text-gray-800">Address</p>
                  <p className="text-sm text-gray-600">{contacts.address}</p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
