import axios from "axios";
import React, { useState } from "react";

import {
  FaBook,
  FaDollarSign,  // Affiliate Link এর জন্য নতুন আইকন
  FaMoneyBillAlt, // Your Income এর জন্য নতুন আইকন
  FaSignOutAlt,
} from "react-icons/fa";
import { config } from "../../config";

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;
const Sidebar = ({
  isSidebarOpen,
  selectedMenu,
  setSelectedMenu,
  toggleSidebar,
  navigate,
  isOverlayVisible,
  setIsSidebarOpen,
  setIsOverlayVisible,
}) => {
  const [isCourseMenuOpen, setIsCourseMenuOpen] = useState(false);
  const [isHeroMenuOpen, setIsHeroMenuOpen] = useState(false);
  const [isTestimonialMenuOpen, setIsTestimonialMenuOpen] = useState(false);
  const [isInstructorMenuOpen, setIsInstructorMenuOpen] = useState(false);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);

    // মোবাইল ডিভাইসের জন্য সাইডবার লুকানোর শর্ত
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
      setIsOverlayVisible(false);
    }
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found! Redirecting to login page.');
        window.location.href = '/login';
        return;
      }
  
      const response = await axios.post(
        `${apiUrl}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // লোকালি স্টোর করা টোকেন পাঠানো হচ্ছে
          },
        }
      );
  
      if (response.data.message) {
        // লোকাল স্টোরেজ থেকে টোকেন মুছে ফেলুন
        localStorage.removeItem('token');
        alert('আপনি সফলভাবে লগআউট করেছেন।');
        // লগইন পেজে রিডাইরেক্ট
        window.location.href = '/login';
      } else {
        alert('Unexpected response. Please check server.');
      }
    } catch (error) {
      console.error('লগআউট ব্যর্থ হয়েছে:', error);
      if (error.response) {
        alert(`Error: ${error.response.data.message || 'Something went wrong'}`);
      } else {
        alert('Network error. Please try again.');
      }
    }
  };

  return (
    <div
      className={`bg-gradient-to-b mt-[90px] md:mt-2 pt-10 from-blue-800 to-blue-600 text-white flex-shrink-0 overflow-y-auto
        ${isSidebarOpen ? "w-64" : "w-20"} 
        transition-all duration-300 fixed md:relative z-40 h-full top-0 left-0`}
    >
      <ul className="space-y-6 m-0 p-0 pt-20">

        {/* Affiliate Menu */}
        <li
          onClick={() => handleMenuClick("affiliate")}
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300
            ${selectedMenu === "affiliate" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"}`}
        >
          <FaDollarSign size={20} />
          <span
            className={`transition-all duration-300 ${
              isSidebarOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            Affiliate Link
          </span>
        </li>

        {/* Income Menu */}
        <li
          onClick={() => handleMenuClick("income")}
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300
            ${selectedMenu === "income" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"}`}
        >
          <FaMoneyBillAlt size={20} />
          <span
            className={`transition-all duration-300 ${
              isSidebarOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            Your Income
          </span>
        </li>

        {/* Logout Menu */}
        <li
          onClick={handleLogout}
          className="flex items-center space-x-4 p-3 rounded cursor-pointer hover:bg-red-700 transition-all duration-300"
        >
          <FaSignOutAlt size={20} />
          <span
            className={`transition-all duration-300 ${
              isSidebarOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            Logout
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
