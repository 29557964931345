import React from "react";
import { config } from "../../config";

const imageUrl = config.imageUrl;

const UpdateInstructor = ({
  formData,
  handleChange,
  handleSubmit,
  setPhoto,
  loading,
  error,
}) => {
  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Update Instructor</h2>

      {loading && <p className="text-blue-500 mb-4">Updating Instructor...</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border w-full p-2"
            placeholder="Enter instructor name"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Designation</label>
          <input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
            className="border w-full p-2"
            placeholder="Enter instructor designation"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Description</label>
          <textarea
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            className="border w-full p-2"
            placeholder="Enter instructor description"
            required
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Image</label>
          {formData.image && (
            <img
              src={`${imageUrl}/${formData.image}`}
              alt=""
              className="mb-2"
              height={200}
              width={200}
            />
          )}
          <input
            type="file"
            name="image"
            onChange={(e) => setPhoto(e.target.files[0])}
            className="border w-full p-2"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Instructor"}
        </button>
      </form>
    </div>
  );
};

export default UpdateInstructor;
