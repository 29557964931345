import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { config } from '../../config';

const apiUrl = config.apiUrl;

const ViewApplication = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch applications data from API
    axios
      .get(`${apiUrl}/applications`)
      .then((response) => {
        setApplications(response.data.applications);
        setLoading(false);
      })
      .catch((error) => {
        setError('ডেটা লোড করতে ব্যর্থ। অনুগ্রহ করে আবার চেষ্টা করুন।');
        setLoading(false);
      });
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold text-center text-green-500 mb-6">View Applications</h1>

        {loading ? (
          <p className="text-center text-gray-600">লোড হচ্ছে...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : applications.length === 0 ? (
          <p className="text-center text-gray-500">কোনো আবেদন পাওয়া যায়নি।</p>
        ) : (
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2 text-left">#</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Phone</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Interest</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              {applications.map((application, index) => (
                <tr key={application.id} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2">{index + 1}</td>
                  <td className="border border-gray-300 px-4 py-2">{application.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{application.phone}</td>
                  <td className="border border-gray-300 px-4 py-2">{application.interest}</td>
                  <td className="border border-gray-300 px-4 py-2"> {new Date(application.created_at).toLocaleDateString('en-GB')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ViewApplication;
