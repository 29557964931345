import React from 'react';
import { useParams } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import image from '../assets/images/1726940869736.jpg'; // Replace with actual course image
import { Link } from 'react-router-dom';

const CourseDetails = () => {
  const { courseName } = useParams();

  const course = {
    title: courseName || 'Web Development',
    description: `
      This course will provide you with the fundamentals of web development, including HTML, CSS, JavaScript, and modern frameworks such as React. You will learn how to build responsive websites that adapt seamlessly to different devices. Whether you're a beginner or an experienced developer, this course will help you take your skills to the next level.
    `,
    curriculum: [
      'Introduction to HTML and CSS',
      'JavaScript Basics',
      'Responsive Design with Bootstrap and Tailwind',
      'Introduction to React',
      'Backend Development with Node.js and Express',
      'Deploying Your Web Application'
    ],
    duration: '3 Months',
    price: 15000,
    offer_price: 5000,
    instructor: 'John Doe',
    image: image,
    learn_more: true
  };

  return (
    <>
      <div>
        <Navbar />
      </div>
      <section className="min-h-screen flex flex-col justify-center py-16 bg-gray-100">
        <div className="container mx-auto px-6 lg:px-12">
          <h1 className="text-4xl lg:text-5xl font-extrabold text-gray-900 mb-6 text-center">
            {course.title}
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Course Image */}
            <div className="flex justify-center">
              <img
                src={course.image}
                alt={course.title}
                className="rounded-lg shadow-lg w-full lg:w-2/3 object-cover"
              />
            </div>

            {/* Course Details */}
            <div className="flex flex-col justify-center">
              <p className="text-lg text-gray-700 mb-6">
                {course.description}
              </p>
              <div className="mb-6">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">Curriculum</h3>
                <ul className="list-disc pl-5 text-gray-600 space-y-2">
                  {course.curriculum.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="mb-6">
                <p className="text-gray-800 text-lg font-semibold">
                  Duration: <span className="text-gray-600">{course.duration}</span>
                </p>
                <p className="text-gray-800 text-lg font-semibold">
                  Instructor: <span className="text-gray-600">{course.instructor}</span>
                </p>
              </div>
              <div className="flex items-center mb-6">
                <div>
                  <p className="text-gray-400 line-through text-lg">
                    ৳ {course.price}
                  </p>
                  <p className="text-2xl font-extrabold text-green-500">
                    ৳ {course.offer_price}
                  </p>
                </div>
              </div>

              <button className="py-3 bg-blue-500 text-white rounded-lg w-full font-semibold hover:bg-blue-700 transition duration-300">
                Enroll Now
              </button>
            </div>
          </div>

          <div className="mt-16 text-center">
            <Link
              to="/courses"
              className="inline-flex items-center text-lg font-semibold text-blue-500 hover:text-blue-700 transition-all duration-300"
            >
              Back to Courses
              <BsArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default CourseDetails;
