import axios from "axios";
import React, { useState } from "react";
import {
  FaBook,
  FaChartBar,
  FaCog,
  FaSignOutAlt,
  FaAngleDown,
  FaUserTie, // Instructor icon
  FaHandsHelping, // Help Desk icon
  FaUsers, // Testimonials icon
  FaMoneyBillWave, // Transactions icon
  FaSchool, // Courses icon
  FaNetworkWired, // Marketers icon
} from "react-icons/fa";
import { config } from "../../config";

const apiUrl = config.apiUrl;

const Sidebar = ({
  isSidebarOpen,
  selectedMenu,
  setSelectedMenu,
  toggleSidebar,
  navigate,
  isOverlayVisible,
  setIsSidebarOpen,
  setIsOverlayVisible,
}) => {
  const [isCourseMenuOpen, setIsCourseMenuOpen] = useState(false);
  const [isHeroMenuOpen, setIsHeroMenuOpen] = useState(false);
  const [isTestimonialMenuOpen, setIsTestimonialMenuOpen] = useState(false);
  const [isInstructorMenuOpen, setIsInstructorMenuOpen] = useState(false);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);

    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
      setIsOverlayVisible(false);
    }
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("No token found! Redirecting to login page.");
        window.location.href = "/login";
        return;
      }

      const response = await axios.post(
        `${apiUrl}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message) {
        localStorage.removeItem("token");
        alert("আপনি সফলভাবে লগআউট করেছেন।");
        window.location.href = "/login";
      } else {
        alert("Unexpected response. Please check server.");
      }
    } catch (error) {
      console.error("লগআউট ব্যর্থ হয়েছে:", error);
      if (error.response) {
        alert(`Error: ${error.response.data.message || "Something went wrong"}`);
      } else {
        alert("Network error. Please try again.");
      }
    }
  };

  return (
    <div
      className={`bg-gradient-to-b mt-[90px] md:mt-2 pt-10 from-blue-800 to-blue-600 text-white flex-shrink-0 overflow-y-auto
        ${isSidebarOpen ? "w-64" : "w-20"} 
        transition-all duration-300 fixed md:relative z-40 h-full top-0 left-0`}
    >
      <ul className="space-y-6 m-0 p-0 pt-20">
        {/* Hero Menu */}
        <li
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300
            ${selectedMenu === "hero" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"}`}
          onClick={() => setIsHeroMenuOpen(!isHeroMenuOpen)}
        >
          <FaBook size={20} />
          <span
            className={`transition-all duration-300 ${isSidebarOpen ? "opacity-100" : "opacity-0 hidden"}`}
          >
            Hero
          </span>
          {isSidebarOpen && <FaAngleDown className="ml-auto" />}
        </li>

        {isHeroMenuOpen && isSidebarOpen && (
          <ul className="pl-8 space-y-4">
            <li
              onClick={() => handleMenuClick("heroCreate")}
              className={`flex items-center space-x-2 p-2 rounded cursor-pointer transition-all duration-300 ${
                selectedMenu === "heroCreate" ? "bg-blue-600 font-bold" : "hover:bg-blue-600"
              }`}
            >
              <span>📝 Create Hero</span>
            </li>
            <li
              onClick={() => handleMenuClick("heroOverview")}
              className={`flex items-center space-x-2 p-2 rounded cursor-pointer transition-all duration-300 ${
                selectedMenu === "heroOverview" ? "bg-blue-600 font-bold" : "hover:bg-blue-600"
              }`}
            >
              <span>🦸‍♂️ Hero Overview</span>
            </li>
          </ul>
        )}

        {/* Testimonial Menu */}
        <li
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300 ${
            selectedMenu === "testimonials" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"
          }`}
          onClick={() => setIsTestimonialMenuOpen(!isTestimonialMenuOpen)}
        >
          <FaUsers size={20} />
          <span className={`transition-all duration-300 ${isSidebarOpen ? "opacity-100" : "opacity-0 hidden"}`}>
            Testimonials
          </span>
          {isSidebarOpen && <FaAngleDown className="ml-auto" />}
        </li>

        {isTestimonialMenuOpen && isSidebarOpen && (
          <ul className="pl-8 space-y-4">
            <li
              onClick={() => handleMenuClick("testimonialCreate")}
              className={`flex items-center space-x-2 p-2 rounded cursor-pointer transition-all duration-300 ${
                selectedMenu === "testimonialCreate" ? "bg-blue-600 font-bold" : "hover:bg-blue-600"
              }`}
            >
              <span>📝 Create Testimonial</span>
            </li>
            <li
              onClick={() => handleMenuClick("testimonialOverview")}
              className={`flex items-center space-x-2 p-2 rounded cursor-pointer transition-all duration-300 ${
                selectedMenu === "testimonialOverview" ? "bg-blue-600 font-bold" : "hover:bg-blue-600"
              }`}
            >
              <span>🦸‍♂️ Testimonial Overview</span>
            </li>
          </ul>
        )}

        {/* Courses Menu */}
        <li
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300 ${
            selectedMenu === "courses" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"
          }`}
          onClick={() => setIsCourseMenuOpen(!isCourseMenuOpen)}
        >
          <FaSchool size={20} />
          <span className={`transition-all duration-300 ${isSidebarOpen ? "opacity-100" : "opacity-0 hidden"}`}>
            Courses
          </span>
          {isSidebarOpen && <FaAngleDown className="ml-auto" />}
        </li>

        {isCourseMenuOpen && isSidebarOpen && (
          <ul className="pl-8 space-y-4">
            <li
              onClick={() => handleMenuClick("courseCreate")}
              className={`flex items-center space-x-2 p-2 rounded cursor-pointer transition-all duration-300 ${
                selectedMenu === "courseCreate" ? "bg-blue-600 font-bold" : "hover:bg-blue-600"
              }`}
            >
              <span>📘 Create Course</span>
            </li>
            <li
              onClick={() => handleMenuClick("courseDetails")}
              className={`flex items-center space-x-2 p-2 rounded cursor-pointer transition-all duration-300 ${
                selectedMenu === "courseDetails" ? "bg-blue-600 font-bold" : "hover:bg-blue-600"
              }`}
            >
              <span>📕 Overview</span>
            </li>
          </ul>
        )}

        {/* Instructors Menu */}
        <li
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300 ${
            selectedMenu === "instructors" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"
          }`}
          onClick={() => setIsInstructorMenuOpen(!isInstructorMenuOpen)}
        >
          <FaUserTie size={20} />
          <span className={`transition-all duration-300 ${isSidebarOpen ? "opacity-100" : "opacity-0 hidden"}`}>
            Instructors
          </span>
          {isSidebarOpen && <FaAngleDown className="ml-auto" />}
        </li>

        {isInstructorMenuOpen && isSidebarOpen && (
          <ul className="pl-8 space-y-4">
            <li
              onClick={() => handleMenuClick("instructorCreate")}
              className={`flex items-center space-x-2 p-2 rounded cursor-pointer transition-all duration-300 ${
                selectedMenu === "instructorCreate" ? "bg-blue-600 font-bold" : "hover:bg-blue-600"
              }`}
            >
              <span>📝 Create Instructor</span>
            </li>
            <li
              onClick={() => handleMenuClick("instructorOverview")}
              className={`flex items-center space-x-2 p-2 rounded cursor-pointer transition-all duration-300 ${
                selectedMenu === "instructorOverview" ? "bg-blue-600 font-bold" : "hover:bg-blue-600"
              }`}
            >
              <span>👨‍🏫 Instructor Overview</span>
            </li>
          </ul>
        )}






        {/* Transactions */}
        <li
          onClick={() => handleMenuClick("transactions")}
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300
            ${selectedMenu === "transactions" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"}`}
        >
          <FaMoneyBillWave size={20} />
          <span
            className={`transition-all duration-300 ${
              isSidebarOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            Transactions
          </span>
        </li>

         {/* Marketers */}

         <li
          onClick={() => handleMenuClick("marketers")}
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300
            ${selectedMenu === "marketers" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"}`}
        >
          <FaNetworkWired size={20} />
          <span
            className={`transition-all duration-300 ${
              isSidebarOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            Marketers
          </span>
        </li>

        {/* Help Desk Menu */}


        <li
          onClick={() => handleMenuClick("contact")}
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300
            ${selectedMenu === "contact" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"}`}
        >
          <FaHandsHelping size={20} />
          <span
            className={`transition-all duration-300 ${
              isSidebarOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            Help Desk
          </span>
        </li>

        <li
          onClick={() => handleMenuClick("applications")}
          className={`flex items-center space-x-4 p-3 rounded cursor-pointer transition-all duration-300
            ${selectedMenu === "applications" ? "bg-blue-700 font-bold shadow-lg" : "hover:bg-blue-700"}`}
        >
          <FaHandsHelping size={20} />
          <span
            className={`transition-all duration-300 ${
              isSidebarOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            All Application
          </span>
        </li>

       

        {/* Logout Menu */}
        <li
          onClick={handleLogout}
          className="flex items-center space-x-4 p-3 rounded cursor-pointer hover:bg-red-700 transition-all duration-300"
        >
          <FaSignOutAlt size={20} />
          <span
            className={`transition-all duration-300 ${
              isSidebarOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            Logout
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
