import React, { useEffect, useState } from 'react';
import { easeIn, motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import axios from 'axios';
import { config } from '../config';

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const Courses = () => {
  const [courses, setCourses] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate(); // React Router's useNavigate

  useEffect(() => {
    axios
      .get(`${apiUrl}/courses`)
      .then((response) => {
        setCourses(response.data.courses);
        setLoading(false);
      })
      .catch((error) => {
        setError('ডাটা লোড করতে সমস্যা হয়েছে');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div>
        <Navbar />
      </div>
      <section className="min-h-screen flex flex-col justify-center py-16 bg-gradient-to-r from-gray-50 to-gray-200">
        <div className="container mx-auto">
          <h2 className="text-center text-4xl lg:text-5xl font-extrabold mb-10 text-gray-900">
          আমাদের কোর্সসমূহ
          </h2>
          <p className="text-center text-lg mb-16 text-gray-600">
          আমাদের সেরা প্রশিক্ষণসমূহ অন্বেষণ করুন, যা আপনার ক্যারিয়ার এবং দক্ষতা বৃদ্ধির জন্য বিশেষভাবে তৈরি।
          </p>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 0.5, duration: 0.5, ease: easeIn },
            }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 px-4"
          >
            {courses.map((course, index) => (
              <motion.div
                key={course.id || index}
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.3 },
                }}
                className="flex flex-col p-6 rounded-lg shadow-lg bg-white hover:bg-gradient-to-tl hover:from-blue-500 hover:to-green-400 transition-all duration-500 group"
              >
                <div className="relative w-full mb-6">
                  <img
                    src={`${imageUrl}/${course.image}`}
                    alt={course.name}
                    className="w-full h-64 object-cover rounded-lg"
                  />
                  {course.offer_price && (
                    <span className="absolute top-3 right-3 bg-yellow-400 text-black text-sm font-bold py-1 px-3 rounded-lg">
                      Special Offer
                    </span>
                  )}
                </div>

                <h3 className="text-2xl font-bold text-gray-900 group-hover:text-white mb-4 transition-all duration-500">
                  {course.name}
                </h3>

                <p className="text-gray-600 group-hover:text-gray-100 mb-6 transition-all duration-500">
                  {course.desc}
                </p>

                <div className="flex items-center justify-between mb-6">
                  <div className='flex justify-between gap-2'>
                    <p className="text-gray-400 line-through text-lg group-hover:text-gray-100">
                      ৳ {course.price}
                    </p>
                    <p className="text-2xl font-extrabold text-green-500 group-hover:text-white">
                      ৳ {course.offer_price}
                    </p>
                  </div>

                  {/* <Link
                    to={course.href}
                    className="inline-flex items-center text-lg font-semibold text-blue-500 hover:text-white group-hover:text-white transition-all duration-500"
                  >
                    Learn More
                    <BsArrowRight className="ml-2" />
                  </Link> */}

                  
                </div>

                {/* Enroll Button */}
                <button
                  onClick={() => navigate(`/purchase/${course.id}`)} // Navigate to purchase page with course ID
                  className="py-3 bg-blue-500 text-white rounded-lg w-full font-semibold hover:bg-blue-700 transition duration-300"
                >
                  Enroll Now
                </button>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Courses;
