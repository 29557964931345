import React from 'react';
import image from '../assets/images/1726940869736.jpg';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';

const About = () => {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <section className="min-h-screen bg-gradient-to-r from-blue-100 to-gray-200 py-16 flex items-center">
        <div className="container mx-auto px-6 lg:px-12">
          <div className="text-center mb-12">
            <h1 className="text-5xl font-bold text-gray-900 mb-6">
              আমাদের সম্পর্কে - ইমপালস আইটি সলিউশন
            </h1>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              স্বাগতম ইমপালস আইটি সলিউশন-এ! এটি একটি আধুনিক প্রশিক্ষণ কেন্দ্র, যেখানে আমরা আপনাকে তথ্যপ্রযুক্তি দুনিয়ায় সফল ক্যারিয়ার গড়ার জন্য প্রয়োজনীয় দক্ষতা ও জ্ঞান অর্জনের সুযোগ করে দিচ্ছি। আমাদের লক্ষ্য শুধু প্রশিক্ষণ নয়, বরং আপনাকে একটি উজ্জ্বল ভবিষ্যৎ গড়ার জন্য পুরোপুরি প্রস্তুত করা।
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            {/* Image Section */}
            <div className="relative">
              <img
                src={image}
                alt="About Us"
                className="rounded-lg shadow-lg object-cover w-full h-full"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-blue-600 opacity-20 rounded-lg"></div>
            </div>

            {/* Text Section */}
            <div>
              <h2 className="text-4xl font-extrabold text-gray-800 mb-6">
                আমাদের সেবা
              </h2>
              <p className="text-lg text-gray-600 leading-relaxed mb-6">
                আমাদের এখানে আপনি যেসব বিষয়ে প্রশিক্ষণ নিতে পারবেন:
                <ul className="list-disc ml-6 mt-2 text-gray-600">
                  <li>এফিলিয়েট মার্কেটিং</li>
                  <li>ডিজিটাল মার্কেটিং</li>
                  <li>ফেসবুক মার্কেটিং</li>
                  <li>গ্রাফিক্স ডিজাইন</li>
                  <li>ওয়েব ডেভেলপমেন্ট</li>
                  <li>ভিডিও এডিটিং</li>
                  <li>ব্লগিং</li>
                  <li>ফ্রিল্যান্সিং</li>
                  <li>এমএস ওয়ার্ড ও অফিস টুলস</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="mt-16 grid grid-cols-1 lg:grid-cols-3 gap-12">
            {/* Feature 1 */}
            <div className="text-center">
              <div className="p-6 bg-white shadow-lg rounded-lg">
                <h3 className="text-3xl font-bold text-gray-800 mb-4">
                  অভিজ্ঞ প্রশিক্ষক
                </h3>
                <p className="text-gray-600">
                  দীর্ঘদিনের অভিজ্ঞতা সম্পন্ন প্রশিক্ষকদের কাছ থেকে হাতে-কলমে শেখার সুযোগ।
                </p>
              </div>
            </div>

            {/* Feature 2 */}
            <div className="text-center">
              <div className="p-6 bg-white shadow-lg rounded-lg">
                <h3 className="text-3xl font-bold text-gray-800 mb-4">
                  ব্যবহারিক প্রশিক্ষণ
                </h3>
                <p className="text-gray-600">
                  লাইভ প্রজেক্ট এবং বাস্তব অভিজ্ঞতার মাধ্যমে দক্ষতা অর্জন।
                </p>
              </div>
            </div>

            {/* Feature 3 */}
            <div className="text-center">
              <div className="p-6 bg-white shadow-lg rounded-lg">
                <h3 className="text-3xl font-bold text-gray-800 mb-4">
                  ক্যারিয়ার গাইডেন্স
                </h3>
                <p className="text-gray-600">
                  ব্যক্তিগত পরামর্শের মাধ্যমে চাকরি বা ফ্রিল্যান্সিং ক্যারিয়ারে সফল হওয়ার পথ দেখানো।
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
