import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { config } from '../config';


const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;




const Testimonials = () => {

  const [testimonials, setTestimonials] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    axios
      .get(`${apiUrl}/testimonials`)
      .then((response)=>{
        setTestimonials(response.data.testimonials);
        setLoading(false)
      })
      .catch((error)=>{
        setError("Data Not Fetch!")
        setLoading(false)
      })
  },[])



  if(loading){
    <div>Loading...</div>
  }

  if(error){
    <div>{error}</div>
  }

  return (
    <section className="py-16 bg-gradient-to-r from-gray-50 to-gray-100">
      <div className="container mx-auto px-6 lg:px-12">
        <h2 className="text-4xl font-bold text-gray-900 text-center mb-12">
          What Our Students Say
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105"
            >
              <img
                src={`${imageUrl}/${testimonial.image}`}
                alt={testimonial.name}
                className="rounded-full w-24 h-24 object-cover mb-4 border-4 border-gray-200"
              />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {testimonial.name}
              </h3>
              <p className="text-gray-500 mb-4">{testimonial.feedback}</p>
              <div className="flex justify-center">
                {Array.from({ length: testimonial.rating }, (_, i) => (
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 text-yellow-400"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                      clipRule="evenodd"
                    />
                  </svg>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
