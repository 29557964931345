import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { config } from '../../config';
import { useNavigate } from 'react-router-dom';

const apiUrl = config.apiUrl;

const Apply = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    interest: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Fetch courses only once
  useEffect(() => {
    axios
      .get(`${apiUrl}/courses`)
      .then((response) => {
        setCourses(response.data.courses);
        setLoading(false);
      })
      .catch((error) => {
        setError('কোর্সের তথ্য লোড করতে ব্যর্থ। অনুগ্রহ করে আবার চেষ্টা করুন।');
        setLoading(false);
      });
  }, []);

  // Submit form data
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.phone.match(/^[0-9]{10,13}$/)) {
      alert('সঠিক মোবাইল নম্বর প্রদান করুন (১০-১৩ ডিজিট)।');
      return;
    }
    axios
      .post(`${apiUrl}/applications`, formData)
      .then((response) => {
        navigate('/apply/thanks');
      })
      .catch((error) => {
        console.error('Error submitting data:', error);
        alert('আপনার তথ্য জমা দিতে ব্যর্থ হয়েছে। দয়া করে পরে আবার চেষ্টা করুন।');
      });
  };

  return (
    <div className="bg-gradient-to-r from-green-400 to-blue-500 min-h-screen text-gray-100 flex items-center justify-center py-12">
      <div className="bg-white text-gray-900 rounded-lg shadow-lg max-w-4xl mx-auto p-8 py-12">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold mb-4 text-green-500">
            "আপনার ক্যারিয়ার তৈরি করুন, একদম ফ্রি মাস্টারক্লাস দিয়ে!"
          </h1>
          <p className="text-lg text-gray-600 leading-relaxed">
            আধুনিক স্কিল শেখার এই সুযোগ হাতছাড়া করবেন না। আমাদের মাস্টারক্লাসে অংশ নিয়ে জেনে নিন নতুন যুগের ডিজিটাল মার্কেটিং, ওয়েব ডেভেলপমেন্ট, এবং গ্রাফিক্স ডিজাইনের বেসিক সিক্রেট।
          </p>
        </div>

        <div className="bg-green-50 rounded-lg p-6 my-6">
          <h2 className="text-2xl font-bold text-green-600">
            "কেন এই মাস্টারক্লাস আপনার জন্য?"
          </h2>
          <ul className="list-disc list-inside text-gray-800 mt-4 space-y-2">
            <li>আপনার ক্যারিয়ারে শক্তিশালী শুরু করার জন্য প্রয়োজনীয় গাইডলাইন।</li>
            <li>ফ্রিল্যান্সিং ও চাকরির বাজারের জন্য প্রস্তুতি।</li>
            <li>সরাসরি বিশেষজ্ঞদের কাছ থেকে শেখার সুযোগ।</li>
            <li>শতাধিক সফল শিক্ষার্থীদের সাথে আপনার নাম যুক্ত করার সুযোগ।</li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-bold text-center text-green-500 mb-4">Apply Now for Free Masterclass</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                আপনার নাম
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="shadow appearance-none border border-gray-300 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-green-400"
                placeholder="আপনার নাম লিখুন"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2" htmlFor="phone">
                মোবাইল নম্বর (WhatsApp Number)
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="shadow appearance-none border border-gray-300 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-green-400"
                placeholder="মোবাইল নম্বর লিখুন (১০-১৩ ডিজিট)"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2" htmlFor="interest">
                আপনার পছন্দের বিষয়
              </label>
              {loading ? (
                <p className="text-gray-500">লোড হচ্ছে...</p>
              ) : error ? (
                <p className="text-red-500">{error}</p>
              ) : (
                <select
                  id="interest"
                  name="interest"
                  value={formData.interest}
                  onChange={handleChange}
                  className="shadow appearance-none border border-gray-300 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-green-400"
                  required
                >
                  <option value="">একটি অপশন নির্বাচন করুন</option>
                  {courses.map((course) => (
                    <option key={course.id} value={course.name}>
                      {course.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-green-500 hover:bg-green-700 text-white text-xl font-bold py-3 px-12 rounded focus:outline-none focus:shadow-outline"
              >
                Submit Now
              </button>
            </div>
          </form>
        </div>

        <div className="text-center mt-8">
          <h3 className="text-2xl font-bold text-green-500">এক্সক্লুসিভ অফার:</h3>
          <p className="text-gray-600 text-lg">
            ফ্রি মাস্টারক্লাসে জয়েন করুন এবং পরবর্তী কোর্সে <strong>১৫% ছাড়</strong> পান।
          </p>
        </div>
      </div>
    </div>
  );
};

export default Apply;
