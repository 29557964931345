import React, { useEffect, useState } from "react";
import axios from "axios";
import UpdateHero from "./UpdateHero";
import { config } from "../../config";

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const ViewHero = () => {
  const [heroes, setHeroes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingHero, setEditingHero] = useState(null);
  const [photo, setPhoto] = useState("");
  const [logo, setLogo] = useState("");
  
  const [formData, setFormData] = useState({
    headline: "",
    desc: "",
  });

  // Fetch heroes from API
  useEffect(() => {
    const fetchHeroes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/heroes`);
        console.log(response)
        setHeroes(response.data.heroes);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching heroes:", err);
        setLoading(false);
      }
    };

    fetchHeroes();
  }, []);

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this hero?")) {
      try {
        await axios.delete(`${apiUrl}/herodelete/${id}`);
        setHeroes((prev) => prev.filter((hero) => hero.id !== id));
      } catch (err) {
        console.error("Error deleting hero:", err);
      }
    }
  };

  // Handle Edit Button Click
  const handleEditClick = (hero) => {
    setEditingHero(hero.id);
    setFormData({
      headline: hero.headline,
      desc: hero.desc,
      logo: hero.logo, 
      image: hero.image,
    });
  };

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Handle Form Submit
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!editingHero) {
      setError("No hero selected for editing.");
      return;
    }
  
    // Create a new FormData instance
    const data = new FormData();

    data.append('_method', 'PUT');
    if (logo) data.append('logo', logo);
    if (photo) data.append('image', photo);
  
    // Append other form fields
    data.append("headline", formData.headline);
    data.append("desc", formData.desc);
  
    try {
      await axios.post(`${apiUrl}/heroesupdate/${editingHero}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      alert("Hero updated successfully");
  
      // Update the local hero list
      setHeroes((prev) =>
        prev.map((hero) =>
          hero.id === editingHero ? { ...hero, ...formData, logo, image: photo } : hero
        )
      );
  
      setEditingHero(null); // Close the edit form
    } catch (err) {
      console.error("Error updating hero:", err);
      setError("An error occurred while updating the hero.");
    }
  };
  
  
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4 text-center">Hero List</h2>
      {error && <p className="text-red-500">{error}</p>}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Logo</th>
            <th className="border border-gray-300 px-4 py-2">Headline</th>
            <th className="border border-gray-300 px-4 py-2">Description</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {heroes.map((hero) => (
            <tr key={hero.id}>
              <td className="border border-gray-300 px-4 py-2">
                {hero.logo && (
                  <img
                    src={`${imageUrl}/${hero.logo}`}
                    alt="Logo"
                    className="h-12 w-12 object-cover"
                  />
                )}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {hero.headline}
              </td>
              <td className="border border-gray-300 px-4 py-2">{hero.desc}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleEditClick(hero)}
                  className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(hero.id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingHero && (
       <UpdateHero 
       formData ={formData} 
       handleChange={handleChange}
       handleSubmit = {handleSubmit}
       setPhoto = {setPhoto}
       setLogo = {setLogo}
       loading = {loading} 
       error = {error} 
       />
      )}
    </div>
  );
};

export default ViewHero;
