// Hero.js

import React, { useEffect, useState } from "react";
import { TypeAnimation } from 'react-type-animation';
import Social from "./Hero/Social";
import Photo from "./Hero/Photo";
import axios from "axios";
import { config } from "../config";

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const Hero = () => {
  const [heroes, setHeroes] = useState([]); // Default value as an empty array
  const [error, setError] = useState(null); // Error state
  const [loading, setLoading] = useState(true); // Loading state
  const [image, setImage] = useState(null);

  useEffect(() => {
    axios
      .get(`${apiUrl}/heroes`)
      .then((response) => {
        setHeroes(response.data.heroes || []); // Ensure heroes is an array
        setLoading(false);
        
        if (response.data.heroes.length > 0) {
          setImage(`${imageUrl}/${response.data.heroes[0].image}`); // Set image once data is loaded
        }
      })
      .catch((error) => {
        setError("Data Not Fetch!");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>{error}</div>; // Show error message
  }

  const hero = heroes.length > 0 ? heroes[0] : {};

  return (
    <div className="container mx-auto py-24 px-4">
      <div className="flex flex-col lg:flex-row items-center lg:justify-center gap-8">
        {/* Left side content */}
        <div className="text-center-left order-2 md:order-1 lg:order-1 xl:order-1 lg:w-1/2">
          <p className="text-lg text-gray-400">{hero.headline}</p> {/* Access headline from the first hero */}
          <h1 className="text-2xl font-bold text-white my-4">
            <TypeAnimation
              sequence={[
                'ডিজিটাল মার্কেটিং',
                1000,
                'ওয়েব ডেভেলপমেন্ট',
                2000,
                'গ্রাফিক্স ডিজাইন',
                3000,
                'ফেইসবুক মার্কেটিং',
                4000,
                'এফিলিয়েট মার্কেটিং',
                5000,
                'ভিডিও ইডিটিং',
                6000,
                'ব্লগিং',
                7000,
                'এমএস অফিস এপ্লিকেশন ',
                8000,
                () => {
                  console.log('Sequence completed');
                },
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              style={{
                fontSize: '1.3em',
                display: 'inline-block',
                color: '#2ce72c',
              }}
            />
          </h1>
          <p className="text-lg text-gray-300 mb-6">
            {hero.desc} {/* Access description from the first hero */}
          </p>
          <div className="text-white flex gap-4 justify-center lg:justify-start">
            <Social />
          </div>
        </div>

        {/* Right side photo */}
        <div className="md:order-1 lg:order-2 xl:order-2 lg:w-1/2">
          <Photo onPhoto={image} /> {/* Pass the image */}
        </div>
      </div>

     
    </div>
  );
};

export default Hero;
