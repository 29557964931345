import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Dashboard/Navbar";
import Sidebar from "../../components/Dashboard/Sidebar";
import CreateHero from "./CreateHero";
import ViewHero from "./ViewHero";
import CreateCourse from "./CreateCourse";
import ViewCourses from "./ViewCourses";
import CreateTestimonial from "./CreateTestimonial";
import CreateInstructor from "./CreateInstructor";
import ViewInstructors from "./ViewInstructors";
import ViewTestimonials from "./ViewTestimonials";
import ViewContacts from "./ViewContacts";
import ViewTransactions from "./ViewTransactions";
import ViewMarketers from "./ViewMarketers";
import ViewApplication from "./ViewApplication";


const DashboardHome = () => {
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsOverlayVisible(!isSidebarOpen);
  };

  const handleOverlayClick = () => {
    setIsSidebarOpen(false);
    setIsOverlayVisible(false);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "courseCreate":
        return (
          <div className="flex flex-col justify-center items-center">
            <div className="">
              <CreateCourse onCourseCreated={() => setSelectedMenu('courseDetails')} />
            </div>
          </div>
        );
      case "courseDetails":
        return (
          <div className="text-gray-700">
            <div className="">
              <ViewCourses />
            </div>
          </div>
        );


        case "heroCreate":
          return (
            <div className="text-gray-700">
              <div className="">
                <CreateHero onHeroCreated={() => setSelectedMenu('heroOverview')} />
              </div>
            </div>
          );
        case "heroOverview":
          return (
            <div className="text-gray-700">
              <div className="">
                <ViewHero />
              </div>
            </div>
          );

          case "testimonialCreate":
            return (
              <div className="text-gray-700">
                <CreateTestimonial onTestimonialCreated={() => setSelectedMenu('testimonialOverview')} />
              </div>
            );
          case "testimonialOverview":
            return (
              <div className="text-gray-700">
                <ViewTestimonials />
              </div>
            );

            case "instructorCreate":
              return (
                <div className="text-gray-700">
                  <CreateInstructor onInstructorCreated={() => setSelectedMenu('instructorOverview')} />
                </div>
              );
            case "instructorOverview":
              return (
                <div className="text-gray-700">
                  <ViewInstructors />
                </div>
              );


      case "contact":
        return (
          <div className="text-gray-700">
            <ViewContacts />
          </div>
      );

      case "transactions":
        return (
          <div className="text-gray-700">
            <ViewTransactions />
          </div>
        );

      case "marketers":
        return (
          <div className="text-gray-700">
            <ViewMarketers />
          </div>
        );

      case "applications":
        return (
            <div className="text-gray-700">
              <ViewApplication />
            </div>
          );
      
      default:
        return (
          <div className="text-gray-700">
            Welcome to the Dashboard
          </div>
        );
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 mx-auto">
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} navigate={navigate} />

      {/* Sidebar */}
      {isOverlayVisible && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={handleOverlayClick}
        ></div>
      )}
      <div className="flex flex-col mt-20">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          toggleSidebar={toggleSidebar}
          navigate={navigate}
          isOverlayVisible={isOverlayVisible}
          setIsSidebarOpen={setIsSidebarOpen}
          setIsOverlayVisible={setIsOverlayVisible}
        />
      </div>

      {/* Content Area */}
      <div className="flex-grow p-6 ml-[65px] mr-2 mt-4 transition-all duration-300 pt-24 md:pt-20">
        <div className="bg-white shadow-lg rounded-lg p-6">{renderContent()}</div>
      </div>
    </div>
  );
};

export default DashboardHome;
