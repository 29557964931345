import React, { useEffect, useState } from "react";
import MobileNavbar from "./Navbar/MobileNavbar";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import axios from "axios";
import { config } from "../config";

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const Navbar = () => {
  const location = useLocation(); // Get the current location
  const [isOpen, setIsOpen] = useState(false); // State to handle mobile menu toggle
  const [heroes, setHeroes] = useState([]); // State to store heroes data
  const [error, setError] = useState(null); // Error state
  const [loading, setLoading] = useState(true); // Loading state

  // Automatically set the active menu based on the current URL path
  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location]); // This will update when location changes

  const [activeMenu, setActiveMenu] = useState(location.pathname); // Default active menu state based on location

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the mobile menu open/close state
  };

  const links = [
    { name: "Home", path: "/" },
    { name: "All Courses", path: "/courses" },
    { name: "About Us", path: "/about" },
    { name: "Instructors", path: "/instructors" },
    { name: "Contact", path: "/contact" },
  ];

  useEffect(() => {
    axios
      .get(`${apiUrl}/heroes`)
      .then((response) => {
        setHeroes(response.data.heroes || []); // Set the heroes data
        setLoading(false); // Stop loading
      })
      .catch(() => {
        setError("Data Not Fetch!"); // Handle error
        setLoading(false); // Stop loading
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator
  }

  if (error) {
    return <div>{error}</div>; // Show error message
  }

  return (
    <nav className="p-4 bg-gray-800">
      <div className="container mx-auto flex items-center justify-between">
        {/* Logo */}
        <a href="/" className="text-lg no-underline font-bold text-white">
          {heroes.length > 0 ? (
            <div>
              <img src={`${imageUrl}/${heroes[0].logo}`} alt="Logo" className="h-10" />
            </div>
          ) : (
            "Logo"
          )}
        </a>

        {/* Menu Items for Desktop */}
        <div className="hidden lg:flex lg:items-center lg:space-x-6">
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className={`text-gray-100 hover:text-green-500 hover:border-b-[4px] hover:border-green-500 pb-2 no-underline ${
                activeMenu === link.path ? "text-green-500 border-b-[4px] border-green-500 font-bold underline" : ""
              }`}
            >
              {link.name}
            </Link>
          ))}
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {/* Dropdown Menu for Mobile */}
      {isOpen && (
        <MobileNavbar
          activeMenu={activeMenu}
          handleSetActiveMenu={setActiveMenu}
          toggleMenu={toggleMenu}
        />
      )}
    </nav>
  );
};

export default Navbar;
