import React, { useState } from "react";
import axios from "axios";
import { config } from "../../config";

const apiUrl = config.apiUrl;

const CreateCourse = ({onCourseCreated}) => {
  const [formData, setFormData] = useState({
    name: "",
    desc: "",
    image: null,
    curriculum: "",
    duration: "",
    instructor: "",
    price: "",
    offer_price: "",
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      await axios.post(`${apiUrl}/courses`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert('Course Created Successfully!');
        onCourseCreated();
    } catch (err) {
      setError("An error occurred while creating the course.");
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Create Course</h2>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Course Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <textarea
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            className="border w-full p-2"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Image</label>
          <input
            type="file"
            name="image"
            onChange={handleChange}
            className="border w-full p-2"
            accept="image/*"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Curriculum</label>
          <textarea
            name="curriculum"
            value={formData.curriculum}
            onChange={handleChange}
            className="border w-full p-2"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Duration</label>
          <input
            type="text"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Instructor</label>
          <input
            type="text"
            name="instructor"
            value={formData.instructor}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Price</label>
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Offer Price</label>
          <input
            type="number"
            name="offer_price"
            value={formData.offer_price}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Create Course
        </button>
      </form>
    </div>
  );
};

export default CreateCourse;
