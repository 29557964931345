import React, { useEffect, useState } from 'react';
import { config } from '../../config';
import axios from 'axios';

const apiUrl = config.apiUrl;

const Income = () => {
  const [affIncome, setAffIncome] = useState('');
  const [pendingComission, setPendingComission] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      axios
        .get(`${apiUrl}/marketers/referral`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAffIncome(response.data.balance);
          setLoading(false);
        })
        .catch(() => {
          setError('Failed to fetch referral code!');
        });
    } else {
      setError('User not logged in!');
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      axios
        .get(`${apiUrl}/marketers/pending`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setPendingComission(response.data.total_commission);
          setLoading(false);
        })
        .catch(() => {
          setError('Failed to fetch referral code!');
        });
    } else {
      setError('User not logged in!');
      setLoading(false);
    }
  }, [token]);

  if (error) {
    return <div className="text-red-500 text-center mt-4">{error}</div>;
  }

  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  return (
    <div className="container mx-auto py-12">
      <h1 className="text-4xl font-extrabold text-center mb-8 text-gray-800">
        Income Overview
      </h1>
      <div className="flex flex-col justify-between items-center md:flex-row gap-8">
        {/* Approved Income Section */}
        <div className="bg-white border border-green-300 rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow duration-300 w-full md:w-[45%] text-center transform hover:-translate-y-2 transition-transform duration-300 ease-in-out">
          <p className="text-2xl font-semibold text-green-600 mb-4">Approved Income</p>
          <p className="text-5xl font-bold text-green-800">
          ৳{affIncome ? affIncome : '0'}
          </p>
          <p className="text-sm text-green-500 mt-2">Total approved earnings</p>
        </div>

        {/* Pending Income Section */}
        <div className="bg-white border border-yellow-300 rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow duration-300 w-full md:w-[45%] text-center transform hover:-translate-y-2 transition-transform duration-300 ease-in-out">
          <p className="text-2xl font-semibold text-yellow-600 mb-4">Pending Income</p>
          <p className="text-5xl font-bold text-yellow-800">
          ৳{pendingComission ? pendingComission : '0'}
          </p>
          <p className="text-sm text-yellow-500 mt-2">Awaiting approval</p>
        </div>
      </div>
    </div>
  );
};

export default Income;
