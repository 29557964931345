import React from "react";
import { config } from "../../config";

const imageUrl = config.imageUrl;

const UpdateTestimonial = ({ formData, handleChange, handleSubmit, setPhoto, loading, error }) => {
  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Update Testimonial</h2>

      {loading && <p className="text-blue-500 mb-4">Updating...</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="mb-4">
          <label className="block text-gray-700">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>

        {/* Feedback */}
        <div className="mb-4">
          <label className="block text-gray-700">Feedback</label>
          <textarea
            name="feedback"
            value={formData.feedback}
            onChange={handleChange}
            className="border w-full p-2"
            required
          ></textarea>
        </div>

        {/* Rating */}
        <div className="mb-4">
          <label className="block text-gray-700">Rating (0-5)</label>
          <input
            type="number"
            name="rating"
            value={formData.rating}
            onChange={handleChange}
            className="border w-full p-2"
            min="0"
            max="5"
            step="0.1"
            required
          />
        </div>

        {/* Image */}
        <div className="mb-4">
          <label className="block text-gray-700">Image</label>
          <input
            type="file"
            name="image"
            onChange={(e) => setPhoto(e.target.files[0])}
            className="border w-full p-2"
            accept="image/*"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Testimonial"}
        </button>
      </form>
    </div>
  );
};

export default UpdateTestimonial;
