import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../config';
import Footer from '../components/Footer';
import Navbar from '../components/NavBar';

const apiUrl = config.apiUrl;

const Transaction = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [amount, setAmount] = useState('');
    const [referredBy, setReferredBy] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [course, setCourse] = useState({ name: '', description: '' });

    const { courseId, referrer } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        if (courseId) {
            axios
                .get(`${apiUrl}/courses/${courseId}`)
                .then((response) => {
                    const res = response.data.Course;
                    setAmount(res.offer_price);
                    setCourse({ name: res.name, description: res.description });
                })
                .catch(() => {
                    console.error('Failed to fetch course details');
                });
        }

        if (referrer) {
            setReferredBy(referrer);
        }
    }, [courseId, referrer]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            course_id: courseId,
            name: name,
            mobile: mobile,
            transaction_id: transactionId,
            amount: amount,
            referred_by: referredBy || null,
        };

        try {
            const response = await axios.post(`${apiUrl}/transactions`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setMessage(response.data.message);
            navigate('/thanks')
            setError('');
        } catch (err) {
            setError('Failed to process the transaction. Please try again.');
            setMessage('');
        }
    };

    return (
        <>
        <Navbar />
        <div className="min-h-screen bg-gradient-to-r from-blue-50 to-indigo-100 flex items-center justify-center">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 bg-white shadow-lg rounded-lg overflow-hidden min-h-[80vh]">
                    {/* Left Side: bKash Info */}
                    <div className="bg-indigo-50 p-8 flex flex-col justify-center">
                        <h2 className="text-3xl font-bold text-indigo-700 mb-4">{course.name || 'Your Dream Course'}</h2>
                        <p className="text-gray-600 mb-4">{course.description || 'Invest in yourself today and achieve your goals with this amazing course!'}</p>
                        <p className="text-xl font-semibold text-green-600 mb-6">Special Price: ৳{amount || 'Loading...'}</p>

                        <div className="bg-gray-50 p-4 border rounded-md shadow-sm">
                            <h3 className="text-lg font-semibold text-gray-700 mb-2">bKash Payment Instructions</h3>
                            <p className="text-gray-600">Send the course fee to the following bKash account:</p>
                            <p className="text-xl font-bold text-indigo-600 mt-2">+8801635494643(bkash personal)</p>
                            <p className="text-gray-600 mt-2">
                                Use this number for the payment and provide the *Transaction ID* in the form on the right.
                                Ensure the payment is made before submitting.
                            </p>
                        </div>
                    </div>

                    {/* Right Side: Payment Form */}
                    <div className="p-8 flex flex-col justify-center">
                        <h2 className="text-2xl font-semibold text-center text-gray-700 mb-6">Complete Your Purchase</h2>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Name */}
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-600">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="mt-1 block w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    required
                                />
                            </div>

                            {/* Mobile */}
                            <div>
                                <label htmlFor="mobile" className="block text-sm font-medium text-gray-600">Mobile</label>
                                <input
                                    type="text"
                                    id="mobile"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    className="mt-1 block w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    required
                                />
                            </div>

                            {/* Transaction ID */}
                            <div>
                                <label htmlFor="transactionId" className="block text-sm font-medium text-gray-600">Transaction ID</label>
                                <input
                                    type="text"
                                    id="transactionId"
                                    value={transactionId}
                                    onChange={(e) => setTransactionId(e.target.value)}
                                    className="mt-1 block w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    required
                                />
                            </div>

                            {/* Referred By */}
                            <div>
                                <label htmlFor="referredBy" className="block text-sm font-medium text-gray-600">Referred By</label>
                                <input
                                    type="text"
                                    id="referredBy"
                                    value={referredBy}
                                    onChange={(e) => setReferredBy(e.target.value)}
                                    className={`mt-1 block w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${
                                        referrer ? 'bg-gray-100 text-gray-500' : ''
                                    }`}
                                    disabled={!!referrer} // Disable only if referrer exists
                                />
                            </div>

                            {/* Submit Button */}
                            <div>
                                <button
                                    type="submit"
                                    className="w-full px-4 py-2 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    Submit Payment
                                </button>
                            </div>
                        </form>

                        {/* Success or Error Message */}
                        {message && <p className="mt-4 text-green-500 text-center">{message}</p>}
                        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
};

export default Transaction;
