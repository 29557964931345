import React from 'react'
import { motion, easeInOut, easeIn } from "framer-motion";
import { Link } from "react-router-dom";
import Social from './Hero/Social';

const Footer = () => {

  const officeAddress = {
      address: "Back Side of Animal Hospital,Puran Bazar Road, Madaripur Sadar, Madaripur",
      support: "Support: support@impulseitsolution.com",
      helpline: "Helpline: (+880) 1756767688",
      available: "(Available: Sat - Thu, 10:00 AM to 9:00 PM)"

    }
  
    const links = [
      { name: "Home", path: "/" },
      { name: "All Courses", path: "/courses" },
      { name: "About Us", path: "/about" },
      { name: "Instructors", path: "/instructors" },
      { name: "Contact", path: "/contact" },
      { name: "Login", path: "/login" },
    ];
  

  
  return (
    <section className='min-h-[50vh] flex lg:flex-row justify-center py-12 lg:px-0 bg-gray-800'>
      <div className='container mx-auto'>
          <motion.div
          initial={{ opacity: 0 }}
          animate={{
              opacity:1,
              transition: {delay: 1.4, duration: 0.4, ease: easeIn}
      
          }}
          className='text-white/70 flex flex-col lg:flex-row lg:justify-between '>
            <div className='flex flex-col items-center lg:items-start'>
              <h3 className='leading-[60px] text-white'>Office Address</h3>
              <p className='text-center md:text-start'>{officeAddress.address}</p>
              <p>{officeAddress.support}</p>
              <p>{officeAddress.helpline}</p>
              <p>{officeAddress.available}</p>
            </div>
            <div className='flex flex-col items-center lg:items-start'>
              <h3 className='leading-[60px] text-white'>Useful Links</h3>
              <div className='flex flex-col items-center lg:items-start'>
                {links.map((link, index)=>(
                  <Link to={link.path} key={index} className='no-underline text-white/70 leading-[30px]'>{link.name}</Link>
                ))}
              </div>
            </div>
            <div className='flex flex-col items-center lg:items-start'>
              <h3 className='text-white leading-[60px]'>follow us</h3>
              <div>
                <Social />
              </div>
            </div>
          </motion.div>
      </div>
    </section>
  )
}

export default Footer
