import React, { useEffect, useState } from "react";
import axios from "axios";
import UpdateContact from "./UpdateContact";
import { config } from "../../config";

const apiUrl = config.apiUrl;

const ViewContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingContact, setEditingContact] = useState(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    course: "",
    questions: "",
  });

  // Fetch contacts from API
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${apiUrl}/contacts`);
        setContacts(response.data.contacts);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching contacts:", err);
        setError("Failed to load contacts.");
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this contact?")) {
      try {
        await axios.delete(`${apiUrl}/contactdelete/${id}`);
        setContacts((prev) => prev.filter((contact) => contact.id !== id));
      } catch (err) {
        console.error("Error deleting contact:", err);
      }
    }
  };

  // Handle Edit Button Click
  const handleEditClick = (contact) => {
    setEditingContact(contact.id);
    setFormData({
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phone,
      course: contact.course,
      questions: contact.questions,
    });
  };

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editingContact) {
      setError("No contact selected for editing.");
      return;
    }

    try {
      await axios.put(`${apiUrl}/contactsupdate/${editingContact}`, formData);
      alert("Contact updated successfully");

      // Update the local contact list
      setContacts((prev) =>
        prev.map((contact) =>
          contact.id === editingContact ? { ...contact, ...formData } : contact
        )
      );

      setEditingContact(null); // Close the edit form
    } catch (err) {
      console.error("Error updating contact:", err);
      setError("An error occurred while updating the contact.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4 text-center">Contact List</h2>
      {error && <p className="text-red-500">{error}</p>}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">First Name</th>
            <th className="border border-gray-300 px-4 py-2">Last Name</th>
            <th className="border border-gray-300 px-4 py-2">Email</th>
            <th className="border border-gray-300 px-4 py-2">Phone</th>
            <th className="border border-gray-300 px-4 py-2">Course</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr key={contact.id}>
              <td className="border border-gray-300 px-4 py-2">{contact.firstName}</td>
              <td className="border border-gray-300 px-4 py-2">{contact.lastName}</td>
              <td className="border border-gray-300 px-4 py-2">{contact.email}</td>
              <td className="border border-gray-300 px-4 py-2">{contact.phone}</td>
              <td className="border border-gray-300 px-4 py-2">{contact.course}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleEditClick(contact)}
                  className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(contact.id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingContact && (
        <UpdateContact
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default ViewContacts;
