import React from "react";
import { config } from "../../config";

const imageUrl = config.imageUrl;

const UpdateCourse = ({
  formData,
  handleChange,
  handleSubmit,
  setImage,
  loading,
  error,
}) => {
  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Update Course</h2>

      {/* Display Loading State */}
      {loading && <p className="text-blue-500 mb-4">Updating Course...</p>}

      {/* Display Error Message */}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Course Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>

        {/* Description */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Description</label>
          <textarea
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            className="border w-full p-2"
            required
          ></textarea>
        </div>

        {/* Curriculum */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Curriculum</label>
          <textarea
            name="curriculum"
            value={formData.curriculum}
            onChange={handleChange}
            className="border w-full p-2"
            required
          ></textarea>
        </div>

        {/* Duration */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Duration</label>
          <input
            type="text"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>

        {/* Instructor */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Instructor</label>
          <input
            type="text"
            name="instructor"
            value={formData.instructor}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>

        {/* Price */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Price</label>
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>

        {/* Offer Price */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Offer Price</label>
          <input
            type="number"
            name="offer_price"
            value={formData.offer_price}
            onChange={handleChange}
            className="border w-full p-2"
            required
          />
        </div>

        {/* Image Upload */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Image</label>
          {formData.image && (
            <img
              src={`${imageUrl}/${formData.image}`}
              alt="Course"
              className="h-32 w-32 object-cover mb-2"
            />
          )}
          <input
            type="file"
            name="image"
            onChange={(e) => setImage(e.target.files[0])}
            className="border w-full p-2"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Course"}
        </button>
      </form>
    </div>
  );
};

export default UpdateCourse;
