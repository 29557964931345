import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { config } from '../../config';

const apiUrl = config.apiUrl;
const webUrl = config.webUrl;

const AffiliateLink = () => {
    const [courses, setCourses] = useState([]);
    const [referral, setReferral] = useState('');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [copySuccess, setCopySuccess] = useState('');

    const token = localStorage.getItem('token');

    useEffect(() => {
        axios.get(`${apiUrl}/courses`)
            .then((response) => {
                setCourses(response.data.courses);
                setLoading(false);
            })
            .catch(() => {
                setError("Data not fetched!");
            });
    }, []);

    useEffect(() => {
        if (token) {
            axios.get(`${apiUrl}/marketers/referral`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                setReferral(response.data.referral_code);
                setLoading(false);
            })
            .catch(() => {
                setError("Failed to fetch referral code!");
            });
        } else {
            setError("User not logged in!");
            setLoading(false);
        }
    }, [token]);

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => {
                setCopySuccess('Link copied to clipboard!');
            })
            .catch(() => {
                setCopySuccess('Failed to copy!');
            });
    };

    if (loading) {
        return <div className="text-center text-xl font-bold">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-red-500">{error}</div>;
    }

    return (
        <div className='container mx-auto py-12'>
            <h1 className="text-3xl font-bold text-center mb-8">Affiliate Courses</h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
                {courses.map((course) => {
                    const courseLink = `${webUrl}/purchase/${course.id}/${referral}`;
                    return (
                        <div key={course.id} className='p-6 border border-gray-200 rounded-lg shadow-lg bg-white hover:shadow-xl transition-shadow duration-300'>
                            <h2 className='font-bold text-2xl text-gray-800 mb-4'>{course.name}</h2>
                            <p className='text-gray-700 mb-4'>Learn the secrets of {course.name} and improve your skills!</p>
                            <div className='bg-gray-100 border border-gray-300 rounded p-3 text-sm text-gray-600 mb-4 break-all overflow-hidden'>
                                {courseLink}
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    onClick={() => copyToClipboard(courseLink)}
                                    className='px-4 py-2 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition-colors duration-300'
                                >
                                    Copy Link
                                </button>
                                <a
                                    href={courseLink}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='px-4 py-2 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600 transition-colors duration-300'
                                >
                                    Visit Link
                                </a>
                            </div>
                        </div>
                    );
                })}
            </div>
            {copySuccess && <p className='mt-4 text-green-500 text-center'>{copySuccess}</p>}
        </div>
    );
}

export default AffiliateLink;
