import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Instructors from "./pages/Instructors";
import CourseDetails from "./pages/CourseDetails";
import DashboardHome from "./pages/Dashboard/DashboardHome";
import UpdateHero from "./pages/Dashboard/UpdateHero";
import Transaction from "./pages/Transaction";
import ThankYou from "./pages/ThankYou";
import Register from "./pages/Register";
import Login from "./pages/Login";
import PrivateRoute from "./pages/PrivateRoute";
import Dashboard from "./pages/Affiliates/Dashboard";
import Logout from "./pages/Logout";
import Apply from "./pages/Apply/Apply";
import ApplyThankYou from "./pages/Apply/ApplyThankYou";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* সাধারণ পেজগুলো */}
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:courseName" element={<CourseDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/instructors" element={<Instructors />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          <Route path="/apply" element={<Apply/>} />
          <Route path="/apply/thanks" element={<ApplyThankYou />} />

          {/* প্রাইভেট পেজগুলো */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute allowedRoles={["admin"]}>
                <DashboardHome />
              </PrivateRoute>
            }
          />
          <Route
            path="/hero-update/:id"
            element={
              <PrivateRoute allowedRoles={["admin"]}>
                <UpdateHero />
              </PrivateRoute>
            }
          />
          <Route
            path="/affiliates"
            element={
              <PrivateRoute allowedRoles={["affiliates"]}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/transaction"
            element={
              <PrivateRoute allowedRoles={["admin", "affiliates"]}>
                <Transaction />
              </PrivateRoute>
            }
          />

          <Route path="/thanks" element={<ThankYou />} />
          <Route path="/purchase/:courseId/:referrer?" element={<Transaction />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
