import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { config } from '../config';

const apiUrl = config.apiUrl;

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(`${apiUrl}/login`, formData);
            const token = response.data.access_token;
            const role = response.data.role;  // Role পাওয়া যাচ্ছে
            const approved = response.data.approved;  // Approved স্ট্যাটাস পাওয়া যাচ্ছে
            localStorage.setItem('token', token);
            localStorage.setItem('role', role);
    
            // Approved এবং Role চেক করে Redirect
            if (approved === true) {  // Approved চেক করা হচ্ছে
                if (role === 'admin') {
                    navigate('/dashboard');  // অ্যাডমিন ড্যাশবোর্ড
                } else if (role === 'affiliates') {
                    navigate('/affiliates');  // এফিলিয়েট ড্যাশবোর্ড
                } else {
                    navigate('/client-dashboard');  // সাধারণ ইউজারদের ড্যাশবোর্ড
                }
            } else {
                alert("Your account is not approved yet. Please wait for admin approval.");
            }
        } catch (error) {
            alert('Login failed. Please check your credentials or account approval status.');
        }
    };
    

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-bold text-center mb-6">Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
                    >
                        Login
                    </button>
                </form>
                <div className="mt-4 text-center">
                    <p>Don't have an account?</p>
                    <button
                        onClick={() => navigate('/register')}
                        className="mt-2 w-full bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition-colors"
                    >
                        Register
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;
