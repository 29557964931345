import React, { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../config";

const apiUrl = config.apiUrl;

const ViewMarketers = () => {
  const [marketers, setMarketers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch marketers from API
  useEffect(() => {
    const fetchMarketers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/marketers`);
        setMarketers(response.data.marketers); // Assuming API returns a list of marketers
        setLoading(false);
      } catch (err) {
        console.error("Error fetching marketers:", err);
        setError("Failed to load marketers. Please try again later.");
        setLoading(false);
      }
    };

    fetchMarketers();
  }, []);

  // Handle Approve/Unapprove
  const toggleApproval = async (id, currentApproval) => {
    try {
      await axios.post(`${apiUrl}/marketers/${id}/toggleApproval`);
      setMarketers((prev) =>
        prev.map((marketer) =>
          marketer.id === id ? { ...marketer, approved: !currentApproval } : marketer
        )
      );
      alert("Approval status updated successfully!");
    } catch (err) {
      console.error("Error updating approval status:", err);
      setError("Failed to update approval status. Please try again.");
    }
  };

  // Handle Role Update
  const updateRole = async (id, role) => {
    const roles = ["client", "admin", "affiliates"];
    const currentIndex = roles.indexOf(role);
    const nextRole = roles[(currentIndex + 1) % roles.length]; // Cycle to the next role

    try {
      await axios.post(`${apiUrl}/marketers/${id}/updateRole`, { role: nextRole });
      setMarketers((prev) =>
        prev.map((marketer) => (marketer.id === id ? { ...marketer, role: nextRole } : marketer))
      );
      alert("Role updated successfully!");
    } catch (err) {
      console.error("Error updating role:", err);
      setError("Failed to update role. Please try again.");
    }
  };

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this marketer?")) {
      try {
        await axios.delete(`${apiUrl}/marketers/${id}`);
        setMarketers((prev) => prev.filter((marketer) => marketer.id !== id));
        alert("Marketer deleted successfully!");
      } catch (err) {
        console.error("Error deleting marketer:", err);
        setError("Failed to delete marketer. Please try again.");
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Marketers List</h2>
      {error && <p className="text-red-500">{error}</p>}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Email</th>
            <th className="border border-gray-300 px-4 py-2">Referral Code</th>
            <th className="border border-gray-300 px-4 py-2">Balance</th>
            <th className="border border-gray-300 px-4 py-2">Role</th>
            <th className="border border-gray-300 px-4 py-2">Approval</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {marketers.map((marketer) => (
            <tr key={marketer.id}>
              <td className="border border-gray-300 px-4 py-2">{marketer.name}</td>
              <td className="border border-gray-300 px-4 py-2">{marketer.email}</td>
              <td className="border border-gray-300 px-4 py-2">{marketer.referral_code}</td>
              <td className="border border-gray-300 px-4 py-2">{marketer.balance}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => updateRole(marketer.id, marketer.role)}
                  className={`px-3 py-1 rounded ${
                    marketer.role === "client"
                      ? "bg-blue-600 text-white hover:bg-blue-700"
                      : marketer.role === "admin"
                      ? "bg-purple-600 text-white hover:bg-purple-700"
                      : "bg-green-600 text-white hover:bg-green-700"
                  }`}
                >
                  {marketer.role.charAt(0).toUpperCase() + marketer.role.slice(1)} {/* Capitalize role */}
                </button>
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {marketer.approved ? (
                  <span className="text-green-500 font-bold">Approved</span>
                ) : (
                  <span className="text-red-500 font-bold">Unapproved</span>
                )}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => toggleApproval(marketer.id, marketer.approved)}
                  className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 mr-2"
                >
                  {marketer.approved ? "Unapprove" : "Approve"}
                </button>
                <button
                  onClick={() => handleDelete(marketer.id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewMarketers;
