import React from 'react';
import { FaCheckCircle, FaClock, FaAward, FaUserShield } from 'react-icons/fa';

const WhyChoose = () => {
  const features = [
    {
      icon: <FaCheckCircle className="text-5xl text-green-500" />,
      title: 'উচ্চ মানের সেবা',
      description: 'আমরা শুধু সেরা সেবা প্রদান করি। আমাদের কোর্স এবং সেবা আপনার প্রয়োজনের দিকে খেয়াল রেখে তৈরি করা হয়েছে।',
    },
    {
      icon: <FaClock className="text-5xl text-blue-500" />,
      title: 'সময়মতো সহায়তা',
      description: 'আমরা ২৪/৭ গ্রাহক সহায়তা প্রদান করি, যাতে আপনার যেকোনো সমস্যা দ্রুত সমাধান হয়।',
    },
    {
      icon: <FaAward className="text-5xl text-yellow-500" />,
      title: 'অভিজ্ঞ ট্রেইনার',
      description: 'আমাদের দলটি ইন্ডাস্ট্রির অভিজ্ঞ এবং স্বীকৃত সার্টিফিকেটধারী বিশেষজ্ঞদের সমন্বয়ে গঠিত।',
    },
    {
      icon: <FaUserShield className="text-5xl text-purple-500" />,
      title: 'গ্রাহক সন্তুষ্টি',
      description: 'আপনার সন্তুষ্টি আমাদের প্রাধান্য। আমরা নিশ্চিত করি যে আপনি আমাদের সেবায় সন্তুষ্ট থাকবেন।',
    },
  ];

  return (
    <section className="py-16 bg-gradient-to-r from-gray-100 to-gray-200">
      <div className="container mx-auto px-6 lg:px-12">
        <h2 className="text-4xl font-bold text-gray-900 text-center mb-12">
          আমাদের কেন বেছে নিবেন?
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-8 text-center transition-transform transform hover:scale-105"
            >
              <div className="flex flex-col items-center mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
