import React, { useEffect, useState } from "react";
import axios from "axios";
import UpdateCourse from "./UpdateCourse";
import { config } from "../../config";

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const ViewCourses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingCourse, setEditingCourse] = useState(null);
  const [image, setImage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    desc: "",
    curriculum: "",
    duration: "",
    instructor: "",
    price: "",
    offer_price: "",
  });

  // Fetch courses from API
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/courses`);
        setCourses(response.data.courses);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching courses:", err);
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this course?")) {
      try {
        await axios.delete(`${apiUrl}/coursedelete/${id}`);
        setCourses((prev) => prev.filter((course) => course.id !== id));
      } catch (err) {
        console.error("Error deleting course:", err);
      }
    }
  };

  // Handle Edit Button Click
  const handleEditClick = (course) => {
    setEditingCourse(course.id);
    setFormData({
      name: course.name,
      desc: course.desc,
      curriculum: course.curriculum,
      duration: course.duration,
      instructor: course.instructor,
      price: course.price,
      offer_price: course.offer_price,
      image: course.image,
    });
  };

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Handle Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editingCourse) {
      setError("No course selected for editing.");
      return;
    }

    const data = new FormData();
    data.append("_method", "PUT");

    if (image) data.append("image", image);
    data.append("name", formData.name);
    data.append("desc", formData.desc);
    data.append("curriculum", formData.curriculum);
    data.append("duration", formData.duration);
    data.append("instructor", formData.instructor);
    data.append("price", formData.price);
    data.append("offer_price", formData.offer_price);

    try {
      await axios.post(
        `${apiUrl}/coursesupdate/${editingCourse}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert("Course updated successfully");

      // Update the local course list
      setCourses((prev) =>
        prev.map((course) =>
          course.id === editingCourse
            ? { ...course, ...formData, image: image }
            : course
        )
      );

      setEditingCourse(null); // Close the edit form
    } catch (err) {
      console.error("Error updating course:", err);
      setError("An error occurred while updating the course.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Course List</h2>
      {error && <p className="text-red-500">{error}</p>}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Image</th>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Instructor</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => (
            <tr key={course.id}>
              <td className="border border-gray-300 px-4 py-2">
                {course.image && (
                  <img
                    src={`${imageUrl}/${course.image}`}
                    alt="Course"
                    className="h-12 w-12 object-cover"
                  />
                )}
              </td>
              <td className="border border-gray-300 px-4 py-2">{course.name}</td>
              <td className="border border-gray-300 px-4 py-2">
                {course.instructor}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleEditClick(course)}
                  className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(course.id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingCourse && (
        <UpdateCourse
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setImage={setImage}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};

export default ViewCourses;
