import React from 'react';
const ApplyThankYou = () => {
  return (
    <div className="bg-gradient-to-r from-green-400 to-blue-500 min-h-screen flex flex-col items-center justify-center text-white pt-20">
      {/* থ্যাংক ইউ মেসেজ */}
      <div className="bg-white text-gray-800 rounded-lg shadow-lg max-w-lg p-8 text-center">
        <h1 className="text-4xl font-bold text-green-500 mb-4">Thank You!</h1>
        <p className="text-lg">
          আপনার আবেদন সফলভাবে জমা হয়েছে! আমাদের টিম আপনার তথ্য পর্যালোচনা করে শীঘ্রই আপনাকে যোগাযোগ করবে।
        </p>
        <div className="mt-6">
          <img
             src={`${process.env.PUBLIC_URL}/04f7eebe299c6d87a6e1c4361a029d58.jpg`}
            alt="Thank you"
            className="rounded-lg shadow-lg mx-auto"
          />
        </div>

        {/* একশন সেকশন */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold text-blue-600">আমাদের সাথেই থাকুন!</h2>
          <p className="text-gray-700 mt-2">
            আমাদের অন্যান্য প্রোগ্রামের আপডেট পেতে ফেসবুক পেজটি ফলো করুন।
          </p>
          <div className="flex justify-center mt-4">
            <a
              href="#"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Follow on Facebook
            </a>
          </div>
        </div>
      </div>

      {/* কন্টাক্ট ইনফো */}
      <div className="text-center mt-8">
        <p className="text-lg font-medium">
          আপনার যদি কোনো প্রশ্ন থাকে, আমাদের সাথে যোগাযোগ করুন:
        </p>
        <p className="mt-2">
          <strong>WhatsApp:</strong> +8801756767688
        </p>
        <p>
          <strong>Phone:</strong> +8801756767688
        </p>
      </div>
    </div>
  );
};

export default ApplyThankYou;
