import React, { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../config";

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const ViewTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingTransaction, setEditingTransaction] = useState(null); // For storing the transaction being edited
  const [editFormData, setEditFormData] = useState({
    course_id:"",
    name: "",
    mobile: "",
    transaction_id: "",
    amount:"",
    referred_by: ""
  });

  // Fetch transactions from API
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`${apiUrl}/transactions`);
        setTransactions(response.data.transactions); // Assuming the response is an array
        setLoading(false);
      } catch (err) {
        console.error("Error fetching transactions:", err);
        setError("Failed to load transactions. Please try again later.");
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this transaction?")) {
      try {
        await axios.delete(`${apiUrl}/transactiondelete/${id}`);
        setTransactions((prev) => prev.filter((transaction) => transaction.id !== id));
        alert("Transaction deleted successfully!");
      } catch (err) {
        console.error("Error deleting transaction:", err);
        setError("An error occurred while deleting the transaction.");
      }
    }
  };

  // Handle Approve
  const handleApprove = async (id) => {
    if (window.confirm("Are you sure you want to approve this transaction?")) {
      try {
        await axios.post(`${apiUrl}/transactions/approve/${id}`);
        setTransactions((prev) =>
          prev.map((transaction) =>
            transaction.id === id ? { ...transaction, approval: true } : transaction
          )
        );
        alert("Transaction approved successfully!");
      } catch (err) {
        console.error("Error approving transaction:", err);
        setError("An error occurred while approving the transaction.");
      }
    }
  };

  // Handle Edit Button Click
  const handleEdit = (transaction) => {
    setEditingTransaction(transaction.id);
    setEditFormData({
      course_id:transaction.course_id,
      name: transaction.name,
      mobile: transaction.mobile,
      transaction_id: transaction.transaction_id,
      amount: transaction.amount,
      referred_by: transaction.referred_by
    });
  };

  // Handle Edit Form Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Save Edited Transaction
  const handleSaveEdit = async () => {
    try {
      await axios.put(`${apiUrl}/transactionsupdate/${editingTransaction}`, editFormData);
      setTransactions((prev) =>
        prev.map((transaction) =>
          transaction.id === editingTransaction ? { ...transaction, ...editFormData } : transaction
        )
      );
      alert("Transaction updated successfully!");
      setEditingTransaction(null);
    } catch (err) {
      console.error("Error updating transaction:", err);
      setError("An error occurred while updating the transaction.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Transactions List</h2>
      {error && <p className="text-red-500">{error}</p>}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Course</th>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Mobile</th>
            <th className="border border-gray-300 px-4 py-2">Transaction ID</th>
            <th className="border border-gray-300 px-4 py-2">Amount</th>
            <th className="border border-gray-300 px-4 py-2">Referred By</th>
            <th className="border border-gray-300 px-4 py-2">Approval</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <td className="border border-gray-300 px-4 py-2">{transaction.course_id || "N/A"}</td>
              <td className="border border-gray-300 px-4 py-2">{transaction.name}</td>
              <td className="border border-gray-300 px-4 py-2">{transaction.mobile}</td>
              <td className="border border-gray-300 px-4 py-2">{transaction.transaction_id}</td>
              <td className="border border-gray-300 px-4 py-2">{transaction.amount}</td>
              <td className="border border-gray-300 px-4 py-2">{transaction.referred_by || "N/A"}</td>
              <td className="border border-gray-300 px-4 py-2">
                {transaction.approval ? (
                  <span className="text-green-500 font-bold">Approved</span>
                ) : (
                  <button
                    onClick={() => handleApprove(transaction.id)}
                    className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                  >
                    Approve
                  </button>
                )}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleDelete(transaction.id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
                <button
                  onClick={() => handleEdit(transaction)}
                  className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 ml-2"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit form for editing a transaction */}
      {editingTransaction && (
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-4">Edit Transaction</h3>
          <form>
          <div className="mb-4">
              <label className="block text-gray-700">Course ID:</label>
              <input
                type="text"
                name="course_id"
                value={editFormData.course_id}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Name:</label>
              <input
                type="text"
                name="name"
                value={editFormData.name}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Mobile:</label>
              <input
                type="text"
                name="mobile"
                value={editFormData.mobile}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Transaction ID:</label>
              <input
                type="text"
                name="transaction_id"
                value={editFormData.transaction_id}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Amount:</label>
              <input
                type="text"
                name="amount"
                value={editFormData.amount}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Referred By:</label>
              <input
                type="text"
                name="referred_by"
                value={editFormData.referred_by}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 w-full"
              />
            </div>
            <button
              type="button"
              onClick={handleSaveEdit}
              className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
            >
              Save
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ViewTransactions;
