import React from "react";
import { config } from "../../config";

const imageUrl = config.imageUrl;

const UpdateHero = ({ formData, handleChange, handleSubmit, setLogo, setPhoto, loading, error }) => {
  return (
    <div className="p-6 max-w-lg mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Update Hero</h2>
      
      {/* Display Loading State */}
      {loading && <p className="text-blue-500 mb-4">Updating Hero...</p>}
      
      {/* Display Error Message */}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      <form onSubmit={handleSubmit}>
        {/* Logo Upload */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Logo</label>
          <img src={`${imageUrl}/${formData.logo}`} alt="" height={300} width={300} />
          <input
            type="file"
            name="logo"
            onChange={(e)=>setLogo(e.target.files[0])}
            className="border w-full p-2"
          />
        </div>

        {/* Headline */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Headline</label>
          <input
            type="text"
            name="headline"
            value={formData.headline}
            onChange={handleChange}
            className="border w-full p-2"
            placeholder="Enter hero headline"
            required
          />
        </div>

        {/* Description */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Description</label>
          <textarea
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            className="border w-full p-2"
            placeholder="Enter hero description"
            required
          ></textarea>
        </div>

        {/* Image Upload */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Image</label>
          <img src={`${imageUrl}/${formData.image}`} alt="" height={200} width={200} />
          <input
            type="file"
            name="image"
            onChange={(e)=>setPhoto(e.target.files[0])}
            className="border w-full p-2"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Hero"}
        </button>
      </form>
    </div>
  );
};

export default UpdateHero;
