import React from 'react';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-green-100 to-blue-100">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg text-center">
                <div className="mb-6">
                    <img
                        src="https://cdn-icons-png.flaticon.com/512/148/148767.png"
                        alt="Success Icon"
                        className="w-24 mx-auto"
                    />
                </div>
                <h1 className="text-3xl font-bold text-green-600 mb-4">Congratulations!</h1>
                <p className="text-gray-700 text-lg">
                    Your payment was successful! Thank you for enrolling in our course.
                </p>
                <p className="mt-2 text-gray-600">
                    We are excited to have you onboard. You'll receive an email with further details shortly.
                </p>
                <div className="mt-6">
                    <button
                        onClick={() => navigate('/courses')}
                        className="px-6 py-3 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700"
                    >
                        Go Back to Courses
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
