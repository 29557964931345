import React from 'react';
import { Link } from "react-router-dom";
import { FaGithub, FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa";

const Social = () => {
  return (
    <div className="flex gap-4 justify-center items-center mt-4">
      <Link 
        to="https://github.com/Danial3264"
        className="p-2 bg-green-700 text-white rounded-full shadow-lg hover:bg-green-500 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
      >
        <FaGithub className="text-2xl" />
      </Link >
      <Link 
        to="https://www.facebook.com/profile.php?id=61568260492386"
        className="p-2 bg-blue-700 text-white rounded-full shadow-lg hover:bg-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
      >
        <FaFacebook className="text-2xl" />
      </Link >
    </div>
  );
}

export default Social;
