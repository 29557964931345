import React from 'react';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import TopCourses from './TopCourses';
import Testimonials from '../components/Testimonials';
import WhyChoose from '../components/WhyChoose';

const Home = () => {
  

  return (
    <>
      <div className="font-sans bg-gray-800">
        <NavBar />
        <Hero />
      </div>
      <div id='services'>
        <TopCourses />
      </div>
      <WhyChoose />
      <Testimonials />
      <Footer />
    </>
    
  );
};

export default Home;
