import React, { useEffect, useState } from "react";
import axios from "axios";
import UpdateTestimonial from "./UpdateTestimonial";
import { config } from "../../config";

const apiUrl = config.apiUrl;
const imageUrl = config.imageUrl;

const ViewTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingTestimonial, setEditingTestimonial] = useState(null);
  const [photo, setPhoto] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    feedback: "",
    rating: "",
  });

  // Fetch testimonials from API
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${apiUrl}/testimonials`);
        setTestimonials(response.data.testimonials);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching testimonials:", err);
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  // Handle Delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this testimonial?")) {
      try {
        await axios.delete(`${apiUrl}/testimonialdelete/${id}`);
        setTestimonials((prev) => prev.filter((testimonial) => testimonial.id !== id));
      } catch (err) {
        console.error("Error deleting testimonial:", err);
      }
    }
  };

  // Handle Edit Button Click
  const handleEditClick = (testimonial) => {
    setEditingTestimonial(testimonial.id);
    setFormData({
      name: testimonial.name,
      feedback: testimonial.feedback,
      rating: testimonial.rating,
    });
  };

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Handle Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!editingTestimonial) {
      setError("No testimonial selected for editing.");
      return;
    }

    const data = new FormData();
    data.append("_method", "PUT");
    if (photo) data.append("image", photo);

    data.append("name", formData.name);
    data.append("feedback", formData.feedback);
    data.append("rating", formData.rating);

    try {
      await axios.post(`${apiUrl}/testimonialsupdate/${editingTestimonial}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Testimonial updated successfully");

      // Update the local testimonial list
      setTestimonials((prev) =>
        prev.map((testimonial) =>
          testimonial.id === editingTestimonial
            ? { ...testimonial, ...formData, image: photo }
            : testimonial
        )
      );

      setEditingTestimonial(null); // Close the edit form
    } catch (err) {
      console.error("Error updating testimonial:", err);
      setError("An error occurred while updating the testimonial.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow rounded">
      <h2 className="text-2xl font-bold mb-4">Testimonials List</h2>
      {error && <p className="text-red-500">{error}</p>}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Image</th>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Feedback</th>
            <th className="border border-gray-300 px-4 py-2">Rating</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {testimonials.map((testimonial) => (
            <tr key={testimonial.id}>
              <td className="border border-gray-300 px-4 py-2">
                {testimonial.image && (
                  <img
                    src={`${imageUrl}/${testimonial.image}`}
                    alt="Testimonial"
                    className="h-12 w-12 object-cover"
                  />
                )}
              </td>
              <td className="border border-gray-300 px-4 py-2">{testimonial.name}</td>
              <td className="border border-gray-300 px-4 py-2">{testimonial.feedback}</td>
              <td className="border border-gray-300 px-4 py-2">{testimonial.rating}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleEditClick(testimonial)}
                  className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(testimonial.id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingTestimonial && (
        <UpdateTestimonial
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setPhoto={setPhoto}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};

export default ViewTestimonials;
